import Vue from 'vue'
import VueRouter from 'vue-router'
const CustomApplication = () => import(/* webpackChunkName: 'phone' */ '../phone/CustomApplication')
const explain = () => import(/* webpackChunkName: 'phone' */ '../phone/explain')
const basicInfo = () => import(/* webpackChunkName: 'phone' */ '../phone/basicInfo')
const pbInfoMantion = () => import(/* webpackChunkName: 'phone' */ '../phone/pbinfomantion')
const hcrequire = () => import(/* webpackChunkName: 'phone' */ '../phone/hcrequire')
const RandomAccessories = () => import(/* webpackChunkName: 'phone' */ '../phone/RandomAccessories')
const BrandService = () => import(/* webpackChunkName: 'phone' */ '../phone/BrandService')
const preview = () => import(/* webpackChunkName: 'phone' */ '../phone/preview')
const management = () => import(/* webpackChunkName: 'phone' */ '../phone/management')
const Projectlist = () => import(/* webpackChunkName: 'phone' */ '../phone/Projectlist')
const news = () => import(/* webpackChunkName: 'phone' */ '../phone/news')
const Userinformation = () => import(/* webpackChunkName: 'phone' */'../phone/Userinformation')
const register = () => import(/* webpackChunkName: 'phone' */ '../phone/register')
const login = () => import(/* webpackChunkName: 'phone' */ '../phone/login')
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
} // 重复点击路由报错的问题
const routes = [
  // { path: '/', redirect: 'explain' },
  { path: '/', redirect: 'explain' },
  { path: '/phone', redirect: 'explain' },
  { path: '/CustomApplication', name: 'CustomApplication', component: CustomApplication },
  { path: '/explain', name: 'explain', component: explain },
  { path: '/basicinfo', name: 'basicinfo', component: basicInfo },
  { path: '/pbInfoMantion', name: 'pbInfoMantion', component: pbInfoMantion },
  { path: '/hcrequire', name: 'hcrequire', component: hcrequire },
  { path: '/randomaccessories', name: 'randomaccessories', component: RandomAccessories },
  { path: '/brandservice', name: 'brandservice', component: BrandService },
  { path: '/preview', name: 'preview', component: preview },
  { path: '/management', name: 'management', component: management },
  { path: '/projectlist', name: 'projectlist', component: Projectlist },
  { path: '/news', name: 'news', component: news },
  { path: '/userinformation', name: 'userinformation', component: Userinformation },
  { path: '/register', name: 'register', component: register },
  { path: '/login', name: 'login', component: login },
]
Vue.use(VueRouter)



const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // 路由拦截
  // from从哪里来
  // to到哪去
  // next() 放行
  // console.log(to, from)
  // 只要不是去 Login 登录页 就判断是否带有token
  // 先拿到 token
  const token = localStorage.getItem('token')
  console.log(token)
  if (to.path === '/explain' || token || to.path === '/register') {
    next()
  } else {
    setTimeout(() => {
      next('/register')
    }, 1000)
  }
})

export default router
