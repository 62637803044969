import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import formCreate from "@form-create/element-ui"
import touch from 'vue-directive-touch'   // 滑动
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from 'moment'
import './assets/css/base.css'
import wx from 'weixin-js-sdk';

Vue.use(ElementUI);
Vue.use(formCreate)
Vue.use(Vant)
Vue.use(touch)
Vue.config.productionTip = false
// axios.defaults.baseURL = 'http://scheme.dnopen.com'
axios.defaults.baseURL = 'https://iotapi.joinusad.com'
Vue.prototype.axios = axios
Vue.prototype.moment = moment
Vue.prototype.wx = wx

axios.interceptors.request.use(function (config) {
  const token = "Bearer " + localStorage.getItem('token')
  const openid = localStorage.getItem('openid') // 线上
  // const openid = JSON.parse(localStorage.getItem("openid")) // 本地选择职位
  config.headers.Authorization = token
  config.headers.openid = openid
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  // console.log(response)
  return response.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

Vue.filter('dateFilter', function (value) {
  return moment(value * 1000).format('YYYY-MM-DD HH:mm:ss')
})
Vue.prototype.getQuery = getQuery
function getQuery(name) {
  var url = window.location.href;
  var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

  if (result == null || result.length < 1) {
    return "";
  }
  return decodeURI(result[1]);
}
let weixin = function () {
  axios.post('/api/frontend/config', {
    url: location.href.split('#')[0]
  }).then((response) => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，
      appId: response.appId, // 必填，公众号的唯一标识
      timestamp: '' + response.timestamp, // 必填，生成签名的时间戳
      nonceStr: response.nonceStr, // 必填，生成签名的随机串
      signature: response.signature,// 必填，签名
      jsApiList: ['hideMenuItems', 'updateAppMessageShareData', 'hideAllNonBaseMenuItem', 'showMenuItems', 'chooseImage'] // 必填，需要使用的JS接口列表
    });
    wx.ready(function () {
      wx.hideMenuItems({
        menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline', 'menuItem:share:qq', 'menuItem:share:QZone', 'menuItem:openWithSafari', 'menuItem:openWithQQBrowser', 'menuItem:favorite', 'menuItem:share:facebook', 'menuItem:share:weiboApp', 'menuItem:copyUrl', 'menuItem:openWithQQBrowser', 'menuItem:openWithSafari'],
      });
      wx.hideAllNonBaseMenuItem();
    });

    wx.error(function (res) {
      // alert('wx.error: '+JSON.stringify(res));
      // console.log(res)
    });
    // console.log(response);
  })
}

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  weixin()
});
// $f = formCreate.create(rules)
// $f.config = {
//   submitBtn: false,
//   resetBtn: false
// }
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
